class Elementor_Nav_Menu {
    static instance;

    static getInstance() {
        if (!Elementor_Nav_Menu.instance) {
            Elementor_Nav_Menu.instance = new Elementor_Nav_Menu();
        }
        return Elementor_Nav_Menu.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }


    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-nav-menu.default', ($scope) => {
            this.setupToggleButton($scope);
            this.setupMenu($scope);
        });
    }

    setupMenu($scope) {
        let subMenusMinWidth = $scope.find('nav.elementor-nav-menu--main').data('submenusminwidth');
        let subMenusMaxWidth = $scope.find('nav.elementor-nav-menu--main').data('submenusmaxwidth');
        $scope.find('.elementor-nav-menu').smartmenus({
            subIndicatorsText: '',
            subIndicatorsPos : 'append',
            subMenusMinWidth : subMenusMinWidth+'px',
            subMenusMaxWidth : subMenusMaxWidth+'px',
            // subMenusSubOffsetY: 100,
            showTimeout      : 0,
        });
    }

    setupToggleButton($scope) {
        if (this.isMobileMenu($scope)) {
            // Code Button
            let $toggle = $scope.find('.elementor-menu-toggle');
            $toggle.on('click', () => {
                $toggle.toggleClass('elementor-active');
                this.toggleMenu($scope, $toggle, $toggle.hasClass('elementor-active'));
            });
        }
    }

    isMobileMenu($scope) {
        return $scope.find('.elementor-nav-menu--mobile-enable').length > 0;
    }

    toggleMenu($scope,$toggle, show) {
        let $dropdownMenu = $scope.find('.elementor-nav-menu--dropdown.elementor-nav-menu__container');;
        let settings      = $scope.data('settings');
        if (show) {
            $dropdownMenu.hide().slideDown(250, function () {
                $dropdownMenu.css('display', '');
            });

            if (settings.full_width) {
                $dropdownMenu.css(this.menuFullWidth($scope, $toggle, $dropdownMenu));
            }

        } else {
            $dropdownMenu.show().slideUp(250, function () {
                $dropdownMenu.attr('style', '');
            });
        }
    }

    menuFullWidth($scope,$toggle, $dropdownMenu) {
        let $container     = $('body'),
            containerWidth = $container.outerWidth(),
            elementOffset  = $dropdownMenu.offset().left,
            containerOffset = $container.offset().left;

        return {
            'top'  : $toggle.outerHeight(),
            'left' : containerOffset - elementOffset + 'px',
            'width': containerWidth,
        }
    }
}


Elementor_Nav_Menu.getInstance();