class Elementor_Brand{
    static instance;

    static getInstance() {
        if (!Elementor_Brand.instance) {
            Elementor_Brand.instance = new Elementor_Brand();
        }
        return Elementor_Brand.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-brand.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

        } );
    }
}


Elementor_Brand.getInstance();